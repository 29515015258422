.app {
    width: 100%;
    height: 100%;
}

.year {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.month {
    margin: 5px 5px 15px 5px;
}

.month-name {
    color: #ccbe88;
    font-weight: bold;
}

.day {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.date {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50px;
    border: none;
    outline: none;
}

.date.in-month:hover {
    cursor: pointer;
    background: #ccbe88;
}

.today {
    background: #ccbe88;
}

.date.prev-month,
.date.next-month {
    color: grey;
}

.rbc-calendar {
    padding: 1rem 0;
    min-height: calc(100% - 79px);
    height: auto;
}

.calendar-box {
    background-color: #F4F6F9;
    padding: 0 2rem;
    transition: 0.35s all;
}

.rbc-month-view {
    background-color: #fff;
}

span.rbc-toolbar-label {
    margin: 10px 0;
    font-size: 1.35rem;
    color: #212121;
    font-weight: 700;
    line-height: 1.167;
    letter-spacing: 0em;
    font-family: poppins, Helvetica, sans-serif;
}

.rbc-month-header .rbc-header {
    font-size: 1.05rem;
    color: #212121;
    font-weight: 600;
    line-height: 1.167;
    padding: 0.4rem 0.6rem;
}

.rbc-toolbar .rbc-btn-group {
    background: #6c778e;
    border-radius: 50px;
    padding: 0.5rem;
}

.rbc-toolbar .rbc-btn-group button {
    border: 0;
    border-radius: 80px !important;
    background: #6c778e;
    box-shadow: none;
    color: #fff;
    font-weight: 600;
    line-height: 1;
    font-size: 0.9rem;
    padding: 0.9rem 1.5rem 0.7rem;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    font-family: poppins, Helvetica, sans-serif;
}

.rbc-toolbar .rbc-btn-group button.rbc-active {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
}

.rbc-row-content .rbc-date-cell {
    padding: 5px;
}

.rbc-date-cell .rbc-button-link {
    background: #f0f4f8;
    padding: 0.5rem;
    border-radius: 50%;
    font-weight: 500;
    height: 35px;
    width: 35px;
    line-height: 22px;
    color: #212121;
}

.rbc-row .rbc-row-segment {
    padding: 0 5px 5px 5px;
}

.rbc-date-cell.rbc-current .rbc-button-link {
    background: #212121;
    color: #fff;
}

.rbc-row-bg .rbc-today {
    background-color: #fff9dc;
}

.rbc-row-bg .rbc-off-range-bg {
    background: #f0f4f8;
}

.rbc-month-view {
    background-color: #fff;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

}

.rbc-toolbar .rbc-btn-group:first-child {
    background: #000;
    padding: 0.5rem;
    /* border: 1px solid #6c778e; */
}

.rbc-toolbar .rbc-btn-group:first-child button {
    background: transparent;
    color: inherit;
    border-right: 1px solid #6c778e;
    margin-right: -1px;
    color: white;
    background: grey;
    border-radius: 50px !important;
    margin: 2px 4px;
}

.rbc-toolbar .rbc-btn-group:first-child button:last-child {
    border-right: 0;
}

.rbc-toolbar .rbc-btn-group:first-child button:first-child {
    display: none;
}


.schedule-main-box h1 {
    margin: 0.5rem 0 2rem 0;
    font-size: 1.65rem;
    color: #212121;
    font-weight: 700;
    line-height: 1.167;
    letter-spacing: 0em;
    font-family: poppins, Helvetica, sans-serif;
}

.schedule-main-box {
    padding: 2rem 2rem 0 0;
}

.schedule-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.schedule-list ul li {
    margin-bottom: 1rem;
}

.schedule-list ul li:last-child {
    margin-bottom: 0;
}

.schedule-list li a {
    background-color: #fff;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: block;
    color: inherit;
    text-decoration: none;
    overflow: hidden;
}

.schedule-list-box {
    display: flex;
    background-color: #fff;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    cursor: pointer;
}

.schedule-info-box {
    width: 100%;
    padding: 1rem 2rem 1rem 1rem;
    position: relative;
}

.schedule-date {
    position: relative;
    min-width: 90px;
    text-align: center;
    padding: 1rem 1rem 0.5rem 1rem;
    background: #2a2e3e;
    color: #fff;
}

.schedule-date h6 {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: poppins, Helvetica, sans-serif;
}

.schedule-date h5 {
    margin: 0.5rem 0;
    font-weight: 600;
    font-size: 1.9rem;
    line-height: 1;
    text-transform: uppercase;
    font-family: poppins, Helvetica, sans-serif;
}

.schedule-date span {
    font-size: 13px;
}

.schedule-date .devider-line {
    position: absolute;
    background: red;
    width: 0.5rem;
    top: 0;
    bottom: 0;
    right: 0;
}

.schedule-info-box h6 {
    margin: 10px 0;
    font-size: 1.05rem;
    color: #212121;
    font-weight: 600;
    line-height: 1.167;
    letter-spacing: 0em;
}

.schedule-info-box:after {
    content: "";
    position: absolute;
    height: 0.6rem;
    width: 0.6rem;
    border-top: 3px solid #000;
    border-right: 3px solid #000;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    right: 0.9rem;
    margin: auto;
    opacity: 0.5;
}

@media only screen and (max-width: 1023px) {
    .rbc-toolbar .rbc-btn-group button {
        font-size: 0.7rem;
        padding: 0.6rem 1rem 0.5rem;
    }

    span.rbc-toolbar-label {
        font-size: 1.15rem;
    }

    .rbc-month-header .rbc-header {
        font-size: 0.75rem;
    }

    .rbc-date-cell .rbc-button-link {
        height: 30px;
        width: 30px;
        line-height: 17px;
        font-size: 11px;
    }

    .schedule-main-box h1 {
        margin: 0.8rem 0 1.2rem 0;
        font-size: 1.25rem;
    }

    .schedule-list-box {
        flex-direction: column;
    }

    .schedule-date .devider-line {
        bottom: auto;
        right: 0;
        top: 100%;
        left: 0;
        height: 0.5rem;
        width: 100%;
    }

}

@media only screen and (max-width: 899px) {
    .schedule-list-box {
        flex-direction: row;
    }

    .schedule-date .devider-line {
        bottom: 0;
        right: 0;
        top: 0;
        left: auto;
        height: 100%;
        width: 0.5rem;
    }

    .calendar-box {
        padding: 0 1rem;
    }

    .schedule-main-box {
        padding: 1rem 0 0 0;
    }

    .schedule-date h6 {
        font-size: 0.8rem;
    }

    .schedule-date h5 {
        margin: 0.2rem 0;
        font-size: 1.6rem;
    }

    .schedule-info-box h6 {
        margin-top: 0;
    }

    .rbc-toolbar {
        flex-direction: column;
    }
}

.event-map-box {
    position: relative;
    z-index: 1;
}

.event-map-box iframe {
    border: 0;
    width: 100%;
    height: 25vh;
}

.event-details-box {
    background: #fff;
    border-radius: 20px;
    padding: 1.5rem;
    box-shadow: 0 -15px 15px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    color: #000;
    text-align: center;
}

.event-price {
    position: static;
    left: 0;
    background: #2a2d40;
    top: 0;
    font-size: 1.6rem;
    color: #fff;
    padding: 1.3rem 1.6rem 1.9rem 1rem;
    /* border-radius: 0 0 100% 0; */
    border-radius: 0 0 140px 0;
    font-style: italic;
    min-width: 99px;
    width: auto;
}

.event-type-box {
    /* padding-left: 100px; */
    padding-left: 1.5rem;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.event-type-box .devider-line {
    height: 15px;
    width: 15px;
    display: block;
    margin: -2px 0 0 6px;
    border-radius: 50px;
}

.event-top-box h3 {
    margin: 30px 0 20px 0;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
}

.event-time-name-box {
    background: #1288fb;
    color: #fff;
    max-width: 280px;
    margin: 0 auto;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.event-time-name-box span {
    background: #2a2d3f;
    max-width: 60px;
    min-height: 40px;
    margin: 0 0.7rem 0 0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 41px;
    font-weight: 600;
    word-break: break-all;
    padding: 0.5rem;
    height: auto;
}

.event-time-name {
    width: 100%;
    font-size: 14px;
    text-align: left;
}

.event-time-name p {
    margin: 0;
}

.event-middle-box {
    margin: 1.2rem 0 0 0;
    padding: 1.2rem 0 0 0;
    border-top: 1px solid #CCCCCC;
    font-size: 0.95rem;
    line-height: 1.4;
    text-align: start;
}

.event-middle-box p {
    margin-top: 0;
}

.event-bottom-box {
    margin-top: 3rem;
}

.event-bottom-box .btn {
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    border: 0;
    border-radius: 5px;
}

.event-bottom-box .btn+.btn {
    margin-top: 1rem;
}

.btn.btn-black {
    background: #1c1c1c;
}

.btn.btn-red {
    background: #fb0007;
}



/* Aside Box
==================================*/
.header-top {
    background: #fff;
    padding: 15px 15px 15px 30px;
    display: flex;
    align-items: center;
    transition: 0.35s all;
}

.header-top-drawer {
    padding: 15px 15px 15px 315px;
}

.menu-trigher-box {
    width: 25px;
    height: 25px;
}

.user-login-info-box {
    margin-left: auto;
}

.user-login-info figure {
    height: 45px;
    width: 45px;
    min-width: 45px;
    background: #2a2e3e;
    margin: 0;
    color: #fff;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50px;
    margin-right: 0.6rem;
    position: relative;
    overflow: hidden;
}

.user-login-info figure img {
    width: 100%;
    height: auto;
    border-radius: 50px;
}

.user-login-info {
    display: flex;
    align-items: center;
}

.user-info h5 {
    font-size: 1.15rem;
    color: #212121;
    font-weight: 500;
    line-height: 1.167;
    letter-spacing: 0em;
    margin: 0 0 0.2rem 0;
}

.user-info p {
    margin: 0;
}

.box-with-menu {
    position: relative;
}

.box-with-menu .calendar-box {
    padding-left: 332px;
    min-height: calc(100vh - 75px);
    overflow: auto;
}

.calendar-box>.MuiGrid-root {
    height: 90vh;
    min-height: 100%;
}

.aside-menu-box {
    width: 300px;
    background: #1c1c1c;
    padding: 1.5rem;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    min-height: 100vh;
    overflow: auto;
}

/* .aside-logo-box {
    margin-bottom: 1.5rem;
} */

.aside-menu-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.1rem;
    color: #fff;
    font-weight: 500;
    line-height: 1.167;
    letter-spacing: 0.03em;
}

.aside-menu-list ul li {
    /* margin-bottom: 1.4rem; */
}

.aside-menu-list ul a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.aside-menu-list ul .menu-icon {
    width: 25px;
    height: 25px;
    margin-right: 0.5rem;
    opacity: 0.4;
}

.aside-menu-list ul .menu-icon svg {
    fill: #fff;
}

.aside-menu-list ul li:last-child {
    margin-bottom: 0;
}

/* .aside-logo-box img {
    width: 100%;
    height: auto;
} */

@media only screen and (max-width: 899px) {
    .box-with-menu .calendar-box {
        padding-left: 1rem;
    }

    .aside-menu-box {
        width: 250px;
        padding: 1rem;
    }

    .aside-menu-list ul {
        font-size: 0.9rem;
    }

    .aside-menu-list ul .menu-icon {
        width: 20px;
        height: 20px;
        margin-right: 0.4rem;
    }

    .header-top {
        padding: 1rem;
    }
}


.calendar-map-toggle {
    margin-top: 2.5rem;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
}

.calendar-map-toggle span {
    margin: 0 0.5rem;
}

.calendar-map-toggle span:first-child {
    margin-left: 0;
}

.calendar-map-toggle span:last-child {
    margin-right: 0;
}

.calendar-map-toggle button,
.calendar-map-toggle button:hover {
    height: 20px;
    width: 30px;
    font-size: 0;
    background: #16aa1b;
    padding: 0;
    min-width: 30px;
    border-radius: 50px;
    position: relative;
}

.calendar-map-toggle button:after {
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    position: absolute;
    top: 2px;
    bottom: 0;
    left: 3px;
    border-radius: 50px;
}

.calendar-map-toggle .button-map button:after {
    left: 13px;
}

.map-main-box {
    margin: 1rem 0 1.8rem 0;
}

.map-box {
    position: relative;
    width: 100%;
    height: calc(100vh - 12.7rem);
}

.map-box iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border: 0;
}

.add-event-btn .btn {
    background: #1c1c1c;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    padding: 1rem 1.6rem;
    display: inline-block;
    border-radius: 5px;
    letter-spacing: 0.05rem;
    max-width: 330px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.add-event-btn {
    margin-top: -37px;
}

@media only screen and (max-width: 1199px) {
    .add-event-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 899px) {
    .calendar-box>.MuiGrid-root {
        height: inherit;
        min-height: inherit;
    }

    .box-with-menu .calendar-box {
        min-height: inherit;
        overflow: inherit;
    }

    .calendar-map-toggle {
        margin-top: 2rem;
    }

    .rbc-calendar {
        min-height: 60vh;
        height: auto;

    }

    .map-box {
        height: 50vh;
    }

    .top-toggle-box {
        flex-direction: column;
    }
}

.top-toggle-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.top-toggle-box div {
    margin: 0.5rem 0;
}

.add-event-main-box {
    padding: 2rem;
}

.add-event-main h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.event-schedule-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.event-schedule-list li {
    margin-bottom: 1rem;
}

.event-schedule-list li:last-child {
    margin-bottom: 0;
}

.add-event-main .MuiGrid-root {
    min-width: 100%;
    width: 100%;
    margin: 0;

}

.add-event-main .css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding: 0;
}

.event-schedule-list-box {
    background-color: #fff;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: inherit;
    text-decoration: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 1rem;
}

.event-schedule-list-box figure {
    margin: 0 1.1rem 0 0;
    width: 50px;
    min-width: 50px;
}

.add-event-main .event-schedule-info {
    min-width: inherit;
    padding-right: 1rem;
}

.event-schedule-info h4 {
    margin: 0 0 0.2rem 0;
}

.form-description-box input[type="text"],
.form-description-box textarea {
    background-color: #fff;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: block;
    color: inherit;
    text-decoration: none;
    overflow: hidden;
}

.form-description-box fieldset {
    border: 0;
}

.form-description-box .MuiFormControl-root {
    margin-bottom: 0.6rem;
}

.form-description-box textarea {
    min-height: 30vh;
}

.form-description-box .MuiButton-root {
    padding: 1rem;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border: 0;
    border-radius: 5px;
}

.calendar-ui-container .rbc-time-header-cell .rbc-header {
    background: gray;
    color: white;
    height: 35px;
    text-align: center;
    display: grid;
}

.calendar-ui-container .rbc-allday-cell {
    display: none;
}

.calendar-ui-container .rbc-toolbar {
    padding: 0 1rem;
}

.rbc-event {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 42px;
    border-radius: 3px;
    border: none !important;
    padding: 0;
    background-color: #fff;
    /* margin-top: -10px; */
}

div .rbc-event:focus,
div .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto transparent;
}

div .rbc-event.rbc-selected,
div .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #fff;
}

.rbc-event.rbc-multiple-event {
    background: transparent;
    box-shadow: none;
}

.rbc-event-label {
    display: none;
}

.calendar-ui-container .rbc-timeslot-group {
    min-height: 75px;
}

.calendar-ui-container .rbc-label {
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 11px;
    color: #000000;
}

.calendar-ui-container .rbc-btn-group {
    background: #000000;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.calendar-ui-container .rbc-btn-group button.rbc-active {
    background: #C0A771 !important;
}

.calendar-ui-container .rbc-btn-group button {
    background: grey;
    margin: 2px 4px;
}

.calendar-ui-container .rbc-header font {
    font-family: 'Poppins';
}

.calendar-ui-container .rbc-date-cell font {
    font-family: 'Poppins';
}

/* .calendar-ui-container .rbc-toolbar-label {
  display: none;
} */

/* .calendar-ui-container .rbc-toolbar .rbc-btn-group:last-child {
  display: none;
} */

@font-face {
    font-family: 'paladins_straightregular';
    src: url(../../assets/scss/paladinsstraight.ttf);
}

@font-face {
    font-family: 'Viga';
    src: url(../../assets/scss/vigaregular.ttf);
}

/* .MuiModal-root .MuiPaper-root.MuiMenu-paper {
    width: auto !important;
} */

.MuiModal-root .MuiPaper-root.MuiMenu-paper ul li {
    white-space: normal;
}