@media only screen and (max-width: 899px) {

  .menu-open,
  .menu-open header {
    padding-left: 0 !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* -webkit-border-radius: 5px;
  border-radius: 5px; */
}

::-webkit-scrollbar-thumb {
  /* -webkit-border-radius: 5px;
  border-radius: 5px; */
  background: gray;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: gray;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.camp_datepicker_container {
  width: 100%;
}

.camp_datepicker_container .react-datepicker-wrapper {
  width: 100%;
}

.rmdp-container {
  width: 100%;
}

.multi_select_datepicker {
  border: 1px solid #bdbdbd;
  height: 44px;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  background: #fafafa;
  outline: none;
  font-family: Poppins;
  padding: 0 0 0 125px;
}

.MuiDataGrid-root .MuiDataGrid-cellCheckbox,
.MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader,
.MuiDataGrid-row .MuiDataGrid-cell {
  outline: none !important;
}

.MuiPopover-root>.MuiPaper-root {
  /* box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.01) !important; */
  box-shadow: none;
  border: 1px solid #dddddd;
}

.MuiPopover-root>.MuiPaper-root>.MuiBox-root {
  border-radius: 4px;
}

/* .MuiDataGrid-row div:first-child span[data-mui-internal-clone-element="true"] { */
.MuiDataGrid-row div span {
  word-break: break-all;
  display: block;
}

.MuiDataGrid-row div ul {
  margin: 0;
  padding: 0 0 0 0.7rem;
}

.MuiDataGrid-row div ul li {
  word-break: break-all;
  margin: 0.4rem 0;
}